import { Loader } from "@googlemaps/js-api-loader";

document.addEventListener('DOMContentLoaded', () => {
    const mapElement = document.querySelector('#map-projects');
    
    if (mapElement) {
        function createMap(locations) {
            const loader = new Loader ({
                apiKey: "AIzaSyA6a2Ya0UUmUPxSMiGJS-P9r_RBD164jUQ",
                version: "weekly",
                libraries: ["marker"]
            });
        
            loader 
                .importLibrary('maps')
                .then(() => {
                    return loader.importLibrary('marker')
                })
                .then (() => {
                    if (!google.maps.marker || !google.maps.marker.AdvancedMarkerElement) {
                        console.error("AdvancedMarkerElement is not available.");
                        return;
                    }
    
                    const { Map, LatLngBounds } = google.maps;
                    const { AdvancedMarkerElement } = google.maps.marker;
    
    
                    const mapOptions = {
                        center: {lat: 50.97377306791086, lng: 2.9431934028415068},
                        zoom: 12,
                        mapId: "7e1c4c732de8a407",
                        mapTypeControl: false,
                        streetViewControl: false,
                    };
    
                    const map = new Map(document.getElementById("map-projects"), mapOptions);
                    const bounds = new LatLngBounds();
    
                    locations.forEach(location => {
                        const infowindow = new google.maps.InfoWindow({
                            content: `
                                <div class="map-infowindow">
                                    <a class="map-infowindow__link" href="${ location.url}" aria-label="open detailpagina">${ location.title }</a>
                                    <p class="map-infowindow__address">${ location.street }</p>
                                </div>
                            `,
                        });
    
                        const customMarker = document.createElement('div');
                        customMarker.innerHTML = `
                            <div class="map-marker--${ location.type }"></div>
                        `;
    
                        const marker = new AdvancedMarkerElement({
                            position: {lat: location.lat , lng: location.lng},
                            map: map,
                            content: customMarker,
                            title: location.title || "Location"
                        });
    
                        bounds.extend({ lat: location.lat, lng: location.lng });
    
                        marker.addListener('click', () => {
                            infowindow.setPosition({ lat: location.lat, lng: location.lng });
                            infowindow.open({
                                anchor: marker,
                                map,
                            });
                        });
    
                    });
    
                    map.fitBounds(bounds);
    
                })
                .catch((e) => {
                    console.error("Error loading the Google Maps library:", e);
                })
        };
    
        async function fetchCoordinates() {
            const query = `
                query ProjectsQuery {
                    projectsEntries(level: 1) {
                        ... on project_Entry {
                            title
                            f_state
                            f_address {
                                addressLine1
                                latitude
                                longitude
                            }
                            url
                        }
                    }
                }
            `;
        
            const response = await fetch('/api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer GIPXK69MrQYsdMEaJhWePnxJRx140Q0u`
                },
                body: JSON.stringify({ query })
            });
        
            const result = await response.json();
    
            return result.data.projectsEntries.map(entry => ({
                lat: parseFloat(entry.f_address[0].latitude),
                lng: parseFloat(entry.f_address[0].longitude),
                title: entry.title,
                type: entry.f_state,
                url: entry.url,
                street: entry.f_address[0].addressLine1
            }));
        };
        
        fetchCoordinates().then(locations => {
            if (locations.length > 0) {
                createMap(locations);
            } else {
                console.error("No location found");
            }
        });
    };
});

